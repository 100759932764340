import './App.css';

function App() {
  return (

<>
  <header>
    <img id="atom_web_design" src="images/atomlogo.png" alt="Atom Web Design" title="Atom Web Design" width="" />
  </header>
  <div id="telford_web_design">
    <div id="header" class="vcard">
      <h1>Atom Web Ltd.</h1>
      <p class="hidden fn org">Atom Web Design.</p>
      <p id="contact_atom_web_design_by_email"><a href="mailto:contact@atomwebdesign.co.uk" class="email">contact@atomwebdesign.co.uk</a></p>
      <p id="contact_atom_web_design_by_phone" class="tel">07929 733 141</p>
    </div>
  </div>
  <footer>
    <div>
      <p>&copy; Copyright 2015 - Atom Web Ltd - 08468882 - <a href="http://www.atomwebdesign.co.uk">Atom Web Design</a>.</p>
      <p id="webdesign"><a href="http://www.atomwebdesign.co.uk">Web Design and Development, Telford, Shropshire and Staffordshire</a>.</p>
    </div>
  </footer>
</>

  );
}

export default App;
